<template>
    <div>
        
        <div class="pageWrap checkoutWrap pt-5 mt-5">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-xl-5">
                        <div v-if="payment" class="display-card paymentInfo-wrap text-center">
                            <div class="success-icon">
                                <i class="icon-check"></i>
                            </div>
                            <h2>Payment Success</h2>
                            <p>
                                Congratulations! <br> Your payment of <strong>{{ currency }} {{paymentAmount}}</strong> <strong>{{ payment }}</strong> has been successfully processed. 
                                Thank you for your purchase!

                                If you have any questions or need assistance, feel free to reach out to our support team.
                                <br>
                                Thank you for choosing us!
                            </p>
                            <router-link :to="{ name: 'UserDashboard'}" class="univ-btn">
                                Go to Dashboard
                            </router-link>
                        </div>
                        <div v-else class="display-card paymentInfo-wrap text-center">
                            <div class="success-icon">
                                <i class="icon-check"></i>
                            </div>
                            <h2>Free Checkout Success</h2>
                            <p>
                                Congratulations! <br> Your order has been successfully processed. <br>
                                If you have any questions or need assistance, feel free to reach out to our support team.
                                <br>
                                Thank you for choosing us!
                            </p>
                            <router-link :to="{ name: 'UserDashboard'}" class="univ-btn">
                                Go to Dashboard
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </div>
</template>
<script>
import store from "@/store"
import axios from "axios"
import api from "@/services/api"
import Helper from "@/services/helper"
import router from "@/router"

export default {
    name:'PaymentSuccess',
    data() {
        return {
            userId: store.getters.user.id,
            payment: this.$route.params.payment,
            data:this.$route.query.data,
            paymentAmount:0,
            currency: store.getters.currency,

            successDdata:{
                data:this.$route.query.data,
                payment: this.$route.params.payment,
                cartData:{
                    cartId: store.getters.userCart.cart_id,
                    userId: store.getters.userCart.user_id,

                    amount: store.getters.cartTotal,
                    discount: store.getters.amountDiscount,
                    code: store.getters.discountCode,

                    cartItems:store.getters.cartItemSummary,
                }
            }
        };
    },
    mounted() {
        let app = this;
        document.title = 'Payment Success';
        app.$store.dispatch("getCartItems",app.userId);
        if(app.payment){
            app.orderProcessed();
        }
    },
    methods: {
        async orderProcessed() {
            let app = this;
            Helper.showSpinner();
            await axios.post(api.getUrl("/payment/" + app.payment + "/success"), app.successDdata ).then(function (response) {
                if (response.data.success) {
                    app.paymentAmount = response.data.data.total_amount - response.data.data.discount_amount;

                    app.$store.dispatch("getCouponDiscount",{amount:0,code:""});
                    app.$store.dispatch("getCartItems",store.getters.user.id);
                    router.push({ name: "PaymentSuccess" }).catch(()=>{});
                }
            }).finally(() => {
                Helper.hideSpinner();
            });
        },
    },
}
</script>